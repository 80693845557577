<template>
  <div class="card card-body filterItem small flightpagelist" :class="[isShowHotIcon?'bgbox-discount':type==='check-booking'? '':'', selectIdx === idx ? 'selected' : '']" v-if="flight" @click="selectFlight">
    <single-flight-item-app
      :flight="flight"
      :showExtraInfo="true"
      direction="departure"
    />
    <b-form-radio v-if="idx!==-1" v-model="selectIdx" :value="idx" class="flight-select" size="lg"></b-form-radio>
    <single-flight-item-app
      :flight="flight"
      :showExtraInfo="true"
      direction="arrive"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { BFormRadio } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    flight: {
      type: Object,
      default: null,
    },
    categoryName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    idx: {
      type: Number,
      default: -1,
    },
    selectIdx: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SingleFlightItemApp: () => import('@/components/productPage/atoms/SingleFlightItemApp'),
    BFormRadio,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
    }),
    isShowHotIcon() {
      const isProductPage = this.$route.fullPath.indexOf('product-page');
      return !isProductPage && (this.flight.matchingDiscountRuleFO?.roundtripFlightsCode !== null || this.flight.matchingDiscountRuleFO?.ruleType === 2);
      // this.flight.matchingDiscountRuleFO?.priority === 3;
    },
    marketerId() {
      const { marketerId } = this.$route.query;
      return marketerId;
    },
  },
  created() {
  },
  methods: {
    async bookingFlightOnly() {
      const body = {
        packId: this.flight.PCK_ID,
        dateFrom: this.$route.query.fromDate,
        flights: `${this.flight?.fl_id1}${this.flight?.fl_id2}`,
        categoryId: this.$route.query.categoryId,
        adult: this.$route.query.adult,
        child: this.$route.query.child,
        infant: this.$route.query.infant,
      };
      if (this.marketerId) body.marketerId = this.marketerId;
      this.$router.push({ path: '/flight-only', query: body });
    },
    getWeek(str) {
      const week = [
        this.$t('weekName.sun'),
        this.$t('weekName.mon'),
        this.$t('weekName.tue'),
        this.$t('weekName.wed'),
        this.$t('weekName.thu'),
        this.$t('weekName.fri'),
        this.$t('weekName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    convertDateFormat(pDate) {
      const format = 'DD-MM-YYYY';
      return dayjs(pDate).format(format);
    },
    selectFlight() {
      this.$emit('change', this.idx);
    },
  },
};
</script>
<style scoped>
.flashing .filterItem, #filter-content > .filterItem.selected {
  opacity: 1;
  animation: flashing 1s linear 1;
}
@keyframes flashing {
  0% { opacity: 1;}
  25% { opacity: 0.5;}
  50% { opacity: 0.1;}
  75% { opacity: 0.5;}
  100% { opacity: 1;}
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: 22px;
  left: 30px;
  position: absolute;
}

.dVacation-pack .ticketLeft {
  width: 100%;
  position: absolute;
  top: 10px;
}

.flight-img{
  padding-right: 80px;
}
.dVacation-pack{
  padding-bottom: 0px;
}
.ticket-topPart-big, .ticket-bottomPart-big{
  padding-left: 50px;
  padding-right: 50px;
}
.remark{
  margin-right: 45px !important;
}

.box span.d-block{
  min-width: 50px;
}

.price b{
  font-size: 25px;
}
.price.odyssey-prices b{
  font-size: 15px;
}

.dVacation-pack .pricing .price {
  font-weight: normal;
  font-size: 18px;
}

.flight-select {
  right: -3%;
  padding: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.card {
  border-radius: 1rem;
}

@media (min-width: 479px) {
  .bgbox {
    padding: 4% 7.2%;
  }

  #filter-content > .filterItem.selected, .flashing > .filterItem {
    background-image: url("https://ecdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://www.flying.co.il/assets/img/bg1-blue.png");
  }
}
@media ( max-width: 786px){
  .dVacation-pack .ticketLeft {
    position: relative;
    top: 0px;
  }
  .ticket-topPart-big, .ticket-bottomPart-big{
    padding-left: 0px;
    padding-right: 0px;
  }
  .dVacation-pack .ticket-topPart-big .box,
  .dVacation-pack .ticket-bottomPart-big .box {
    min-width: 62px;
    padding: 5px 10px !important;
  }

  .flightpagelist .box-middle img {
    display: block;
  }

  .dVacation-pack .ticketLeft {
    display: block;
  }

}
@media (max-width: 479px) {
  .dVacation-pack.filter-cont .filterItem:not(:first-child) {
    margin-top: 20px;
    z-index: 2;
  }
  .dVacation-pack .flightpagelist .border-md-right [class^="ticket-topPart"] {
    margin-top: 3px;
  }
  .dVacation-pack .pricing .pricing_right .btn-group-sm > .btn,
  .dVacation-pack .pricing .pricing_right .btn-sm {
    margin: 1px;
  }
  .hot-offer-icon {
    width: 50px;
    height: 50px;
    top: -4px;
    left: -4px;
  }
  .pricing_left {
    padding : 3px 20px;
  }

  .flight-img{
    padding-right: 15px;
  }

  .dVacation-pack .flightpagelist .pricing span {
    display: block;
    font-size: 15px;
  }
  .dVacation-pack .flightpagelist .pricing .btn.btn-continue {
    /* float: right; */
    font-size : 15px;
  }
  .flight-select {
    right: -1%;
    padding: 0;
    z-index: 2;
    /* margin-top: -20px; */
  }
 #filter-content > .selected {
    box-shadow: 0px 0px 15px 3px #007bff !important;
  }

}
</style>
